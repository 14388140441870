import { call, takeEvery, put } from "redux-saga/effects";
import {
  getPodcasts,
  getPodcastsSuccess,
  getPodcastsFailure
} from "./actions";
import {
  getPodcastsRequest,
  getTopPodcastsRequest
} from "./api";

function* saga(action) {
  try {
    const { payload } = action;
    // console.log(payload);
    const TopPodcast = yield call(getTopPodcastsRequest);
    const Podcasts = yield call(getPodcastsRequest, payload);
    yield put(getPodcastsSuccess({ Podcasts, TopPodcast }));
  } catch (error) {
    console.log(error);
    yield put(getPodcastsFailure(error));
  }
}

export function* PodcastsWatcher() {
  yield takeEvery(getPodcasts.toString(), saga);
}
