import {token} from '../../config';

export const getSpeakersRequest = () => {
  
  var requestOptions = {
    method: 'GET',
    headers: {Authorization: `Bearer ${token}`},
    redirect: 'follow'
  };

  return fetch("https://kaspersky-podcasts.dev.co-dd.dev:444/api/speakers-plural?populate=Photo ", requestOptions)
    .then(result => result.json())
    .then(data => data)
    .catch(error => error);


}
