import { call, takeEvery, put } from "redux-saga/effects";
import {
  getSpeakers,
  getSpeakersSuccess,
  getSpeakersFailure
} from "./actions";
import {
  getSpeakersRequest
} from "./api";

function* saga(action) {
  try {
    const Speakers = yield call(getSpeakersRequest);
    yield put(getSpeakersSuccess(Speakers));
  } catch (error) {
    console.log(error);
    yield put(getSpeakersFailure(error));
  }
}

export function* SpeakersWatcher() {
  yield takeEvery(getSpeakers.toString(), saga);
}
