import { handleActions } from "redux-actions";
import { combineReducers } from "redux";
import {
  getPodcast,
  getPodcastSuccess,
  getPodcastFailure,
  getPodcastIdle
} from "./actions";

const status = handleActions(
  {
    [getPodcastSuccess]: () => "SUCCESS",
    [getPodcast]: () => "LOADING",
    [getPodcastFailure]: () => "FAILURE",
    [getPodcastIdle]: () => "IDLE",
  },
  "IDLE"
);

const data = handleActions(
  {
    [getPodcastSuccess]: (state, action) => action.payload.data[0].attributes
  },
  null
);

const Podcast = combineReducers({
  status,
  data
});

export { Podcast };
