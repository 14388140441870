import {token} from '../../config';

export const getPodcastsRequest = ( payload ) => {

  const { start } = payload;
  
  var requestOptions = {
    method: 'GET',
    headers: {Authorization: `Bearer ${token}`},
    redirect: 'follow'
  };

  return fetch("https://kaspersky-podcasts.dev.co-dd.dev:444/api/podcast-plural?populate=Platform,File,Thumbnail&pagination[start]=" + (start + 1) + "&pagination[limit]=3&sort[0]=Date%3Adesc", requestOptions)
    .then(result => result.json())
    .then(data => {
      console.log(data)
      return data;
    })
    .catch(error => error);

}


export const getTopPodcastsRequest = () => {

  var requestOptions = {
    method: 'GET',
    headers: {Authorization: `Bearer ${token}`},
    redirect: 'follow'
  };

  return fetch("https://kaspersky-podcasts.dev.co-dd.dev:444/api/podcast-plural?populate=Platform,File,Thumbnail&pagination[page]=1&pagination[pageSize]=1&sort[0]=Date%3Adesc", requestOptions)
    .then(result => result.json())
    .then(data => data)
    .catch(error => error);

}
