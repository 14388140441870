import { createActions } from "redux-actions";

const {
  getPodcasts,
  getPodcastsSuccess,
  getPodcastsFailure,
  getPodcastsIdle,
  getPodcastsClear
} = createActions(
  "GET_PODCASTS",
  "GET_PODCASTS_SUCCESS",
  "GET_PODCASTS_FAILURE",
  "GET_PODCASTS_IDLE",
  "GET_PODCASTS_CLEAR"
);

export {
  getPodcasts,
  getPodcastsSuccess,
  getPodcastsFailure,
  getPodcastsIdle,
  getPodcastsClear
};
