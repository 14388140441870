import { createActions } from "redux-actions";

const {
  getSpeakers,
  getSpeakersSuccess,
  getSpeakersFailure,
  getSpeakersIdle
} = createActions(
  "GET_SPEAKERS",
  "GET_SPEAKERS_SUCCESS",
  "GET_SPEAKERS_FAILURE",
  "GET_SPEAKERS_IDLE"
);

export {
  getSpeakers,
  getSpeakersSuccess,
  getSpeakersFailure,
  getSpeakersIdle
};
