import {token} from '../../config';
export const getPodcastRequest = (payload) => {
 
  var requestOptions = {
    method: 'GET',
    headers: {Authorization: `Bearer ${token}`},
    redirect: 'follow'
  };

  const { slug } = payload;

  return fetch("https://kaspersky-podcasts.dev.co-dd.dev:444/api/podcast-plural/?filters[Slug][$eq]=" + slug + "&populate=Platform,File,Thumbnail ", requestOptions)
    .then(result => result.json())
    .then(data => data)
    .catch(error => error);
  
}
