import { combineReducers } from "redux";
import { Podcasts } from "./components/podcasts/reducer";
import { Podcast } from "./components/podcast/reducer";
import { Speakers } from "./components/speakers/reducer";

export default combineReducers({
  Podcasts,
  Podcast,
  Speakers
});
