import { createActions } from "redux-actions";

const {
  getPodcast,
  getPodcastSuccess,
  getPodcastFailure,
  getPodcastIdle
} = createActions(
  "GET_PODCAST",
  "GET_PODCAST_SUCCESS",
  "GET_PODCAST_FAILURE",
  "GET_PODCAST_IDLE"
);

export {
  getPodcast,
  getPodcastSuccess,
  getPodcastFailure,
  getPodcastIdle
};
