import React from "react";
// import ReactDOM from "react-dom";
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import createStore from "./store";
import { Provider } from 'react-redux';
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const store = createStore();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

serviceWorker.unregister();
