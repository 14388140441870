import { handleActions } from "redux-actions";
import { combineReducers } from "redux";
import {
  getSpeakers,
  getSpeakersSuccess,
  getSpeakersFailure,
  getSpeakersIdle
} from "./actions";

const status = handleActions(
  {
    [getSpeakersSuccess]: () => "SUCCESS",
    [getSpeakers]: () => "LOADING",
    [getSpeakersFailure]: () => "FAILURE",
    [getSpeakersIdle]: () => "IDLE",
  },
  "IDLE"
);

const data = handleActions(
  {
    [getSpeakersSuccess]: (state, action) => [...action.payload.data]
  },
  []
);

const Speakers = combineReducers({
  status,
  data
});

export { Speakers };
