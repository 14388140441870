import React from "react";

const Platforms = (props) => {

  const { list } = props;

  let platforms = [
    {
      title: 'Apple<br/>Podcasts',
      img: 'service-1.svg'
    },
    {
      title: 'Yandex<br/>Music',
      img: 'service-2.svg'
    },
    {
      title: 'ВКонтакте',
      img: 'service-3.svg'
    },
    {
      title: 'Google<br/>Podcasts',
      img: 'service-4.svg'
    },
    {
      title: 'Spotify',
      img: 'service-5.svg'
    },
    {
      title: 'Castro',
      img: 'service-6.svg'
    },
    {
      title: 'Castbox',
      img: 'service-7.svg'
    },
    {
      title: 'Pocket<br/>Casts',
      img: 'service-8.svg'
    }
  ]

  return (
    <div className="section-1__platforms">
      <div className="section-1__platforms-title">
        <h4>
          Слушайте эпизод на любимых платформах
        </h4>
      </div>

      <div className="section-1__platforms-links">
        <div className="section-1__platforms-wrapper col-12 offset-0 col-md-8 offset-md-2">
          <div className="row">

            {list && list.map((item, index) => {
              
              let indexPlatform;

              if (item.Platform == 'Apple Podacasts'){
                indexPlatform = 0;
              }
              if (item.Platform == 'Yandex Music') {
                indexPlatform = 1;
              }
              if (item.Platform == 'ВКонтакте') {
                indexPlatform = 2;
              }
              if (item.Platform == 'Google Podacasts') {
                indexPlatform = 3;
              }
              if (item.Platform == 'Spotify') {
                indexPlatform = 4;
              }
              if (item.Platform == 'Castro') {
                indexPlatform = 5;
              }
              if (item.Platform == 'Castbox') {
                indexPlatform = 6;
              }
              if (item.Platform == 'Pocket Casts') {
                indexPlatform = 7;
              }
              
              let title = platforms[indexPlatform] && platforms[indexPlatform].title,
                  img = platforms[indexPlatform] && platforms[indexPlatform].img;

              return (
                <a href={item.Link} target="_blank" key={item.Platform} className="section-1__platforms-link col-6 col-md-3">
                  <div className="section-1__platforms-link_container">
                    <img src={`/img/${img}`} alt="" />

                    <p className="section-1__platforms-link_title" dangerouslySetInnerHTML={{ __html: title }}></p>
                  </div>

                </a>
              )
            })}

          </div>
        </div>
      </div>
    </div>
  )
}

export default Platforms;
