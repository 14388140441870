import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, useRoutes } from "react-router-dom";
import history from "./history";
import { useDispatch, useSelector } from "react-redux";
import { getSpeakers } from "./components/speakers/actions";

import Home from "./pages/home";
import Podcast from "./pages/podcast";
import "./scss/App.scss";

const AppWrapper = () => {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/:id", element: <Podcast /> },
  ]);
  return routes;
};

const App = () => {
  return (
    <Router>
      <AppWrapper />
    </Router>
  );
};

export default App;
