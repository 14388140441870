import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPodcasts, getPodcastsIdle, getPodcastsClear } from "./actions";

import Item from '../item';

import '../../music-yandex.min.js';

function Podcasts(){
  const dispatch = useDispatch();

  const { status, items, meta } = useSelector(state => state.Podcasts);

  const handleShowMore = (e) => {
    e.preventDefault();

    dispatch(getPodcasts({
      start: items.length
    }));

  }

  useEffect(() => {
    if (status == 'IDLE') {
      dispatch(getPodcastsClear());
      dispatch(getPodcasts({
        start: 0
      }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (status == 'SUCCESS') {
      dispatch(getPodcastsIdle());
    }
  }, [status]);

  return (
    <div>
      <div className="container">
        <div className="section-3__title">
          <h4>
            Предыдущие выпуски
          </h4>
        </div>

        <div className="section-3__wrapper">

          {items && items.map((item, index) => <Item key={item.id} {...item} />)}

        </div>

        {meta.pagination.start + meta.pagination.limit < meta.pagination.total && (
            <p className="section-3__more" onClick={e => handleShowMore(e)}>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="16" fill="#23D1AE"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M17.25 14.75L17.25 9L14.75 9.00001L14.75 14.75H9L9 17.25H14.75L14.75 23L17.25 23L17.25 17.25H23L23 14.75H17.25Z"
                      fill="#1D1D1B"/>
              </svg>

              <span className="section-3__more-text">Показать больше</span>
            </p>
        )}
      </div>

    </div>
  )
}

export default Podcasts;
