import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {getSpeakers} from "./actions";

import { Swiper, Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
Swiper.use([Navigation]);

function Speakers(){
  const dispatch = useDispatch();

  const { status, data } = useSelector(state => state.Speakers);

  useEffect(() => {
    if (status === 'IDLE') {
      dispatch(getSpeakers());
    }
  }, [dispatch]);

  useEffect(() => {
    const breakpoint = window.matchMedia( '(min-width: 768px)' );

    let speakersSwiper;
    const enableSwiper = function() {
      speakersSwiper = new Swiper('.speakers-swiper', {
        slidesPerView: 1.55,
        slidesPerGroup: 1,
        centeredSlides: true,
        initialSlide: 1,
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1.55,
            slidesPerGroup: 1,
            spaceBetween: 25
          },

          // when window width is >= 768px
          768: {
            slidesPerView: 3,
            slidesPerGroup: 1,
            spaceBetween: 0
          }
        }
      });
    }
    const breakpointChecker = function() {

      // if larger viewport and multi-row layout needed
      if ( breakpoint.matches === true ) {

        // clean up old instances and inline styles when available
        if ( speakersSwiper !== undefined ) speakersSwiper.destroy( true, true );

        // or/and do nothing
        return;

        // else if a small viewport and single column layout needed
      } else if ( breakpoint.matches === false ) {

        // fire small viewport version of swiper
        return enableSwiper();

      }

    };

    breakpoint.addListener(breakpointChecker);

    // kickstart
    breakpointChecker();
  });

  return (
    <div className="container speakers">
      <div className="section-2__title">
        <h4>
          О проекте
        </h4>
      </div>

      <div className="section-2__description col-12 offset-0 col-md-8 offset-md-2">
        <p>
          Документально-разговорный подкаст о кибербезопасности. Эксперты «Лаборатории Касперского» Александр
          Гостев, Сергей Голованов и Алексей Андреев рассказывают о расследованиях киберпреступлений и помогают
          разобраться в новых угрозах цифрового мира.
        </p>
      </div>

      <div className="section-2__speakers">
        <div className="speakers-swiper swiper">
          <div className="speakers-swiper-wrapper swiper-wrapper">
            {data && data.map((item, index) => {
              return (
                <div className="section-2__speakers-item swiper-slide col-3" key={index}>
                  <div className="section-2__speakers-item_img">
                    {item.attributes.Photo && <img src={`https://kaspersky-podcasts.dev.co-dd.dev:444${item.attributes.Photo.data.attributes.url}`} alt="" />}
                  </div>

                  <div className="section-2__speakers-item_title">
                    <h4>
                      { item.attributes.Name }
                    </h4>
                  </div>

                  <div className="section-2__speakers-item_description">
                    <p>
                      { item.attributes.Description }
                    </p>
                  </div>
                </div>
              )
            })}
            {/*<div className="section-2__speakers-item swiper-slide col-4">*/}
            {/*  <div className="section-2__speakers-item_img">*/}
            {/*    /!*<img src="../../img/speaker-2.png" srcSet="../../img/speaker-2@2x.png" alt=""/>*!/*/}
            {/*  </div>*/}

            {/*  <div className="section-2__speakers-item_title">*/}
            {/*    <h4>*/}
            {/*      Сергей Голованов*/}
            {/*    </h4>*/}
            {/*  </div>*/}

            {/*  <div className="section-2__speakers-item_description">*/}
            {/*    <p>*/}
            {/*      Главный эксперт по информационной безопасности «Лаборатории Касперского»*/}
            {/*    </p>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*<div className="section-2__speakers-item swiper-slide col-3">*/}
            {/*  <div className="section-2__speakers-item_img">*/}
            {/*    /!*<img src="../../img/speaker-3.png" srcSet="../../img/speaker-3@2x.png" alt=""/>*!/*/}
            {/*  </div>*/}

            {/*  <div className="section-2__speakers-item_title">*/}
            {/*    <h4>*/}
            {/*      Александр Гостев*/}
            {/*    </h4>*/}
            {/*  </div>*/}

            {/*  <div className="section-2__speakers-item_description">*/}
            {/*    <p>*/}
            {/*      Главный технологический эксперт «Лаборатории Касперского»*/}
            {/*    </p>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Speakers;
