import { fork } from "redux-saga/effects";
import { PodcastsWatcher } from "./components/podcasts/saga";
import { PodcastWatcher } from "./components/podcast/saga";
import { SpeakersWatcher } from "./components/speakers/saga";

export default function* () {
  yield fork(PodcastsWatcher);
  yield fork(PodcastWatcher);
  yield fork(SpeakersWatcher);
}
