import { handleActions } from "redux-actions";
import { combineReducers } from "redux";
import {
  getPodcasts,
  getPodcastsSuccess,
  getPodcastsFailure,
  getPodcastsIdle,
  getPodcastsClear
} from "./actions";

const status = handleActions(
  {
    [getPodcastsSuccess]: () => "SUCCESS",
    [getPodcasts]: () => "LOADING",
    [getPodcastsFailure]: () => "FAILURE",
    [getPodcastsIdle]: () => "IDLE",
  },
  "IDLE"
);

const meta = handleActions(
  {
    [getPodcastsSuccess]: (state, action) => action.payload.Podcasts.meta,
  },
  {
    pagination: {}
  }
);

const topPodcast = handleActions(
  {
    [getPodcastsSuccess]: (state, action) => action.payload.TopPodcast.data
  },
  null
);

const items = handleActions(
  {
    [getPodcastsSuccess]: (state, action) => {
      return [
        ...state,
        ...action.payload.Podcasts.data
      ]
    },
    [getPodcastsClear]: () => []
  },
  []
);

const Podcasts = combineReducers({
  status,
  items,
  meta,
  topPodcast
});

export { Podcasts };
