import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { Swiper, Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
Swiper.use([Navigation]);
import {shareLink, apiUrl, trackBaseUrl} from "../../config"

import Platforms from "../platforms";
import Moment from "react-moment";
import 'moment/locale/ru';
const TopItem = (props) => {

  const { id, attributes } = props;

  const { Title, Thumbnail, Date, Description, File, File_url, Platform, Season, Slug } = attributes;

  const [openPopup, setOpenPopup] = useState(false);

  // console.log(attributes);

  useEffect(() => {

    /* сохраняем текстовое поле в переменную text */
    const text = document.querySelector("#popup-" + id + " .form-input__item");

    /* сохраняем кнопку в переменную btn */
    const btn = document.querySelector("#popup-" + id + " .form-input__img");

    /* вызываем функцию при нажатии на кнопку */
    if (btn) {
      btn.onclick = function () {
        text.select();
        document.execCommand("copy");
        btn.classList.add('copied')
        setTimeout(() => {
          btn.classList.remove('copied');
        }, 1000);
      }
    }
    
    const formSwiper = new Swiper('#popup-' + id +' .form-swiper__container', {
      slidesPerView: 9,
      slidesPerGroup: 1,

      navigation: {
        nextEl: ".form-swiper__navigation_next",
        prevEl: ".form-swiper__navigation_prev",
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 6,
          slidesPerGroup: 1,
          spaceBetween: 2
        },

        // when window width is >= 768px
        768: {
          slidesPerView: 6,
          slidesPerGroup: 1,
          spaceBetween: 0
        }
      }
    });

    // Теперь инициализируем всю эту структуру и создадим экземпляр плеера.
    var AudioPlayer = ya.music.Audio;
    AudioPlayer.config.flash.path = "../../dist";

    const player = document.querySelectorAll(".player__item#player__item-"+id);
    player.forEach((item, index) => {
      var dom = {
        player: item,

        play: item.querySelector(".controls_play"),
        playMobile: item.querySelector('.player__item-links_player'),

        progress: {
          bar: item.querySelector(".progress"),
          loaded: item.querySelector(".progress_loaded"),
          current: item.querySelector(".progress_current"),
          position: item.querySelector('.progress_position'),
          duration: item.querySelector('.progress_duration'),
        },

        volume: {
          bar: item.querySelector(".volume"),
          value: item.querySelector(".volume_bar")
        },

        overlay: item.querySelector(".overlay")
      };

      // Предоставим плееру самому решать, какой тип реализации использовать.
      var audioPlayer = new AudioPlayer(null, dom.overlay);
      if (!window.audioPlayers) window.audioPlayers = [];
      window.audioPlayers = [...window.audioPlayers, audioPlayer];
      audioPlayer.initPromise().then(function () {
        // Скрываем оверлей, кнопки управления становятся доступными.
        dom.overlay.classList.add("overlay_hidden");
      }, function (err) {
        // Показываем ошибку инициализации в оверлее.
        dom.overlay.innerHTML = err.message;
        dom.overlay.classList.add("overlay_error");
      });

      // Настроим отображение статуса плеера.
      // Для простого плеера нам достаточно знать, запущено воспроизведение или нет.

      audioPlayer.on(ya.music.Audio.EVENT_STATE, function (state) {
        if (state === ya.music.Audio.STATE_PLAYING) {
          dom.player.classList.add("player_playing");
        } else {
          dom.player.classList.remove("player_playing");
        }
      });

      /* Теперь настроим обновление прогресс-бара. В нем предусмотрены 2 шкалы - шкала загрузки и шкала текущей
      позиции воспроизведения. */

      audioPlayer.on(ya.music.Audio.EVENT_PROGRESS, function (timings) {
        dom.progress.loaded.style.width = (timings.loaded / timings.duration * 100).toFixed(2) + "%";
        dom.progress.current.style.width = (timings.position / timings.duration * 100).toFixed(2) + "%";
        dom.progress.position.innerHTML = secondsToHms(timings.position);

      });

      // dom.progress.duration.innerHTML = (dom.progress.duration);

      // dom.progress.duration.innerHTML = dom.progress.duration.innerHTML;
      /* Теперь нужно настроить взаимодействие с пользователем. Начнем с запуска воспроизведения. */

      var trackUrls = [File.data && 'https://kaspersky-podcasts.dev.co-dd.dev:444' + File.data.attributes.url || File_url];

      var trackIndex = 0;

      var startPlay = function () {
        window.audioPlayers &&
          window.audioPlayers.forEach((player) => {
            player && player.stop();
          });
        var track = trackUrls[index];
        if (audioPlayer.isPreloaded(track)) {
          audioPlayer.playPreloaded(track);
        } else {
          audioPlayer.play(track);
        }
      };
      audioPlayer.preload(trackUrls[index]).then(function () {
        // console.log("Предварительная загрузка началась.");
        // dom.progress.position.innerHTML = secondsToHms(audioPlayer.getPosition(1));
        dom.progress.duration.innerHTML = secondsToHms(audioPlayer.getDuration(1));
        item.querySelector('.player__item-info_time').innerHTML = secondsToHms(audioPlayer.getDuration(1));
        item.querySelector('.player__item-links_download').setAttribute('href', audioPlayer.getSrc(1));
        // console.log("Длительность трека", secondsToHms(audioPlayer.getDuration(1)));
        // console.log("Длительность загруженной части", secondsToHms(audioPlayer.getLoaded(1)));
      }, function (err) {
        console.error("Не удалось начать загрузку.", err);
      }
      );
      
      function secondsToHms(d) {
        d = Number(d);

        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        return ('0' + h).slice(-2) + ":" + ('0' + m).slice(-2) + ":" + ('0' + s).slice(-2);
      }

      item.querySelector('.player__item-title').addEventListener('click', ()=> {
        audioPlayer.pause();
      })

      dom.play.addEventListener("click", function () {
        var state = audioPlayer.getState();

        mobilePlay.classList.toggle('play');

        switch (state) {
          case ya.music.Audio.STATE_PLAYING:
            audioPlayer.pause();
            break;

          case ya.music.Audio.STATE_PAUSED:
            audioPlayer.resume();
            break;

          default:
            startPlay();
            break;
        }
      });
      const mobilePlay = item.querySelector('.js-player-mobile');
      const popupPlayer = item.querySelector('.section-1__item-player');
      if (mobilePlay) {

        mobilePlay.addEventListener("click", function (event) {
          var state = audioPlayer.getState();
          const playerItems = document.querySelectorAll('.player__item-player');

          playerItems.forEach((item) => {
            item.classList.remove('play');
          })
          this.classList.toggle('play');

          popupPlayer.classList.add('play');


          // mobilePlay.classList.add('play');
          switch (state) {
            case ya.music.Audio.STATE_PLAYING:
              audioPlayer.pause();
              break;

            case ya.music.Audio.STATE_PAUSED:
              audioPlayer.resume();
              break;

            default:
              startPlay();
              break;
          }
        });
      }



      /* Добавим немножко удобства для пользователей: сделаем автозагрузку следующего трека после того, как текущий загрузился.
      Для этого потребуется немного изменить функцию `startPlay` и отслеживать момент загрузки трека. */

      audioPlayer.on(ya.music.Audio.EVENT_ENDED, function () {
        trackIndex++;

        if (trackIndex < trackUrls.length) {
          startPlay();
        }
      });

      audioPlayer.on(ya.music.Audio.EVENT_LOADED, function () {
        if (trackIndex + 1 < trackUrls.length) {
          audioPlayer.preload(trackUrls[trackIndex + 1]);
        }
      });

      /* Осталось только настроить навигацию по треку и регулирование громкости: */

      var offsetLeft = function (node) {
        var offset = node.offsetLeft;
        if (node.offsetParent) {
          offset += offsetLeft(node.offsetParent);
        }
        return offset;
      };

      var offsetTop = function (node) {
        var offset = node.offsetTop;
        if (node.offsetParent) {
          offset += offsetTop(node.offsetParent);
        }
        return offset;
      };

      dom.progress.bar.addEventListener("click", function (evt) {
        var fullWidth = dom.progress.bar.offsetWidth;
        var offset = offsetLeft(dom.progress.bar);

        var relativePosition = Math.max(0, Math.min(1, ((evt.pageX || evt.screenX) - offset) / fullWidth));

        var duration = audioPlayer.getDuration();

        audioPlayer.setPosition(duration * relativePosition);

      });


    })

  }, []);

  return (
    <>
      <div className="section-1__wrapper">
        <div className="row">
          <div className="section-1__img col-12 col-md-4">
            {Thumbnail.data && <img src={`https://kaspersky-podcasts.dev.co-dd.dev:444${Thumbnail.data.attributes.url}`} srcSet={`https://kaspersky-podcasts.dev.co-dd.dev:444${Thumbnail.data.attributes.url} 2x`} alt=""/>}

            <div className="section-1__img-title">
              {Season}
            </div>
          </div>
          <div id={`player__item-${id}`} className="section-1__item player__item col-12 col-md-8">
            <div className="section-1__item-info player__item-info">
              <p className="section-1__item-info_date player__item-info_date">
                <Moment locale="ru" format="D MMMM YYYY">
                  {Date}
                </Moment>
              </p>
              <p className="section-1__item-info_time player__item-info_time">
                01:01:26
              </p>
            </div>
            <div className="section-1__item-title player__item-title">
              <h4>
                <Link to={`/${Slug}`}>{Title}</Link>
              </h4>
            </div>
            <div className="section-1__item-description player__item-description">
              <p>
                {Description}
              </p>
            </div>

            <div className="section-1__item-links player__item-links">
              <div className="section-1__item-links_share section-1__item-link player__item-link js-open-popup" onClick={e => setOpenPopup(!openPopup)}>
                <div className="section-1__item-link_img">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.8 4H4V16H16V11.2" stroke="#AEAEAE" strokeWidth="1.5" />
                    <path d="M7 13L16 4M16 4H10.8571M16 4V9.14286" stroke="#AEAEAE" strokeWidth="1.5" />
                  </svg>
                </div>

                <span className="section-1__item-links_share-text player__item-links_share-text">
                  Поделиться
                </span>
              </div>

              <a href={File.data && 'https://kaspersky-podcasts.dev.co-dd.dev:444' + File.data.attributes.url || File_url} download=""
                className="section-1__item-links_download section-1__item-link player__item-link player__item-links_download">
                <div className="section-1__item-link_img">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                      d="M17.1004 13.8369V18.1H2.88989V13.8369H4.31095V16.679H15.6794V13.8369H17.1004ZM14.0523 10.9308L13.0433 9.92187L10.7057 12.2666V1.90002H9.28466V12.2666L6.94702 9.92187L5.93808 10.9308L9.99518 14.9808L14.0523 10.9308Z"
                      fill="#AEAEAE" />
                  </svg>


                </div>

                <span className="section-1__item-links_download-text player__item-links_download-text">
                  Скачать выпуск
                </span>
              </a>

              <div
                className="section-1__item-links_player section-1__item-link player__item-link player__item-links_player js-player-mobile">
                <div className="section-1__item-link_img">
                  <svg className="pause-button" width="15" height="18" viewBox="0 0 15 18" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.5342 9.33803L0.72596 17.7887L0.725961 0.887328L14.5342 9.33803Z"
                          fill="#7EFF33"></path>
                  </svg>
                  <svg className="play-button" width="14" height="16" viewBox="0 0 14 16" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M5.22768 0.225464H0.906738V15.7748H5.22768V0.225464ZM13.0868 0.225464H8.76585V15.7748H13.0868V0.225464Z"
                          fill="#7EFF33"></path>
                  </svg>

                </div>

                <span className="section-1__item-links_share-text player__item-links_share-text">
                  Слушать
                </span>
              </div>
            </div>

            <div className="section-1__item-player player__item-player">
              <div className="player" data-url={File.data && 'https://kaspersky-podcasts.dev.co-dd.dev:444' + File.data.attributes.url || File_url}>
                <div className="player-info">
                  <div className="player-info__icon player-info__favorite" >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.9911 7.03878C9.82613 4.77698 8.10004 3 6 3C3.79086 3 2 4.96861 2 7.39702C2 8.65685 2.482 9.79294 3.25435 10.5946L9.9911 18L16.5792 10.758C17.4481 9.95144 18 8.74507 18 7.39702C18 4.96861 16.2091 3 14 3C11.8973 3 10.1524 4.77295 9.9911 7.03878Z" stroke="#AEAEAE" strokeWidth="1.5"/>
                    </svg>
                  </div>
                  <div className="player-info__title">
                    { Title }
                  </div>
                  <div className="player-info__icon player-info__share" onClick={e => setOpenPopup(!openPopup)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.8 4H4V16H16V11.2" stroke="#AEAEAE" strokeWidth="1.5"></path>
                      <path d="M7 13L16 4M16 4H10.8571M16 4V9.14286" stroke="#AEAEAE" strokeWidth="1.5"></path>
                    </svg>
                  </div>
                </div>
                <div className="controls">
                  <button className="controls_play">
                    <svg className="pause-button" width="14" height="16" viewBox="0 0 14 16" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd"
                        d="M5.22768 0.225464H0.906738V15.7748H5.22768V0.225464ZM13.0868 0.225464H8.76585V15.7748H13.0868V0.225464Z"
                        fill="#7EFF33" />
                    </svg>

                    <svg className="play-button" width="15" height="18" viewBox="0 0 15 18" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.5342 9.33803L0.72596 17.7887L0.725961 0.887328L14.5342 9.33803Z" fill="#7EFF33" />
                    </svg>
                  </button>
                </div>

                <div className="progress">
                  <div className="progress_position">00:00:00</div>
                  <div className="progress_loaded"></div>
                  <div className="progress_current"></div>
                  <div className="progress_duration">00:00:00</div>
                </div>

                <div className="overlay"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {Platform && <Platforms list={Platform} />}

      <div id={`popup-${id}`} className={`popup ${openPopup ? 'active' : ''}`}>
        <div className="popup-shadow" onClick={e => setOpenPopup(!openPopup)}></div>
        <div className="box">

          <div className="form-wrapper active">
            <div className="close js-close" onClick={e => setOpenPopup(!openPopup)}></div>

            <div className="form-container">
              <div className="form-title">
                <p>
                  Поделиться
                </p>
              </div>

              <div className="form-description text">
                <p>
                  Запись подкаста «{Title}»
                </p>
              </div>
              <div className="form-swiper">
                <div className="form-swiper__container swiper">
                  <div className="form-swiper__wrapper swiper-wrapper">
                    <a href={'mailto:?subject=' + Title + '&body=' + `${shareLink}/${Slug}`} className="form-swiper__item swiper-slide" tooltip="Email" flow="up" target="_blank" rel="noreferrer">
                      <img src="/img/share-icon-1.svg" alt="Email" />
                    </a>
                    <a href={'http://vk.com/share.php?url=' + `${shareLink}/${Slug}` + '&title=' + Title} className="form-swiper__item swiper-slide" tooltip="VK" flow="up" target="_blank" rel="nofollow">
                      <img src="/img/share-icon-2.svg" alt="VK" />
                    </a>
                    <a href={'https://connect.ok.ru/offer?url=' + `${shareLink}/${Slug}` + '&title=' + Title} className="form-swiper__item swiper-slide" tooltip="OK" flow="up" target="_blank" rel="nofollow">
                      <img src="/img/share-icon-3.svg" alt="OK" />
                    </a>
                    <a href={"https://wa.me/?text=" + `${shareLink}/${Slug}`} className="form-swiper__item swiper-slide" tooltip="WhatsApp" flow="up">
                      <img src="/img/share-icon-4.svg" alt="WhatsApp" />
                    </a>
                    <a href={'https://t.me/share/url?url=' + `${shareLink}/${Slug}` + '&text=' + Title} className="form-swiper__item swiper-slide" tooltip="Telegram" flow="up" target="_blank" rel="nofollow noreferrer noopener">
                      <img src="/img/share-icon-5.svg" alt="Telegram" />
                    </a>
                    <a href={'https://twitter.com/intent/tweet?text=' + Title + '&url=' + `${shareLink}/${Slug}`} className="form-swiper__item swiper-slide" tooltip="Twitter" flow="up" target="_blank" rel="nofollow noreferrer noopener">
                      <img src="/img/share-icon-6.svg" alt="Twitter" />
                    </a>
                  </div>
                </div>

                <div className="form-swiper__navigation form-swiper__navigation_prev swiper-button-prev">
                  <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="17.094" height="2.56409" transform="matrix(0.712175 0.702002 0.712175 -0.702002 0 12)"
                      fill="white" />
                    <rect width="17.094" height="2.56409"
                      transform="matrix(0.712175 -0.702002 -0.712175 -0.702002 1.82617 13.8)" fill="white" />
                  </svg>
                </div>
                <div className="form-swiper__navigation form-swiper__navigation_next swiper-button-next">
                  <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="17.094" height="2.56409" transform="matrix(0.712175 0.702002 0.712175 -0.702002 0 12)"
                      fill="white" />
                    <rect width="17.094" height="2.56409"
                      transform="matrix(0.712175 -0.702002 -0.712175 -0.702002 1.82617 13.8)" fill="white" />
                  </svg>
                </div>
              </div>

              <div className="form-input">
                <input className="form-input__item" type="text" defaultValue={`${shareLink}/${Slug}`} />

                <div className="form-input__img">
                  <svg className="form-input__img-copy" width="20" height="20" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                      d="M11.5 3.5H4.5V12.5H7V7.5V6H8.5H11.5V3.5ZM13 6H15.5H17V7.5V16.5V18H15.5H8.5H7V16.5V14H4.5H3V12.5V3.5V2H4.5H11.5H13V3.5V6ZM8.5 7.5H15.5V16.5H8.5V7.5Z"
                      fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd"
                      d="M11.5 3.5V6H7V12.5H4.5V3.5H11.5ZM13 6V2H3V14H7V18H17V6H13ZM8.5 7.5V16.5H15.5V7.5H8.5Z"
                      fill="white" />
                  </svg>

                  <svg className="form-input__img-copied" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32" width="32px" height="32px">
                    <path
                      d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z" />
                  </svg>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TopItem;
