import { call, takeEvery, put } from "redux-saga/effects";
import {
  getPodcast,
  getPodcastSuccess,
  getPodcastFailure
} from "./actions";
import {
  getPodcastRequest
} from "./api";

function* saga(action) {
  try {
    const { payload } = action;
    const Podcast = yield call(getPodcastRequest, payload);
    yield put(getPodcastSuccess(Podcast));
  } catch (error) {
    console.log(error);
    yield put(getPodcastFailure(error));
  }
}

export function* PodcastWatcher() {
  yield takeEvery(getPodcast.toString(), saga);
}
