import "regenerator-runtime/runtime";
import { legacy_createStore as createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import sagas from "./rootSaga";
import rootReducer from "./rootReducer";

const createAppStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  let store;
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    store = createStore(
      rootReducer,
      compose(
        applyMiddleware(sagaMiddleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    );
  } else {
    store = createStore(rootReducer, compose(applyMiddleware(sagaMiddleware)));
  }

  sagaMiddleware.run(sagas);

  return store;
};

export default createAppStore;
